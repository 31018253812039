import { useState } from "react";
import InputLabel from "@/Components/InputLabel.jsx";
import TextInput from "@/Components/TextInput.jsx";
import InputError from "@/Components/InputError.jsx";
import PrimaryButton from "@/Components/PrimaryButton.jsx";
import { useForm } from "@inertiajs/react";
import ic_whatsapp from "../../../../icons/whatsapp/WhatsAppButtonGreenMedium.svg";
import useWindowSize from "@/helpers/useWindowSize.js";

export default function FormGeneral({
    videoWatched = false,
    pricesVisible = false,
    className = "",
}) {
    const windowSize = useWindowSize();

    const { data, setData, post, processing, errors, reset } = useForm({
        type: "general",
        name: "",
        email: "",
        phone: "",
        music: "",
        snacks: "",
        origin: "",
        location: "",
        time: "",
        notes: "",
        purpose: "",
        dsgvo: false,
        public: false,
        video_watched: videoWatched,
        prices_visible: pricesVisible,
        contact_via: "",
    });

    const [optionalInfos, setOptionalInfos] = useState(false);
    const submit = (e) => {
        e.preventDefault();
        post(route("inquiry.store"), {
            preserveState: true,
            preserveScroll: true,
        });
    };

    const trackFormClick = (e) => {
        var _paq = (window._paq = window._paq || []);
        _paq.push(["trackEvent", "Form", "clicked", "general"]);
    };

    return (
        <div
            className={`flex flex-col justify-center max-w-4xl mx-auto py-4 p-4 sm:p-8 gap-8 relative ${className}`}
            id={"bookNow"}
        >
            <h2 className="drop-shadow-xl text-2xl sm:text-6xl leading-snug mt-0">
                Interesse?
            </h2>
            <a
                href={`https://wa.me/436805009229?text=${encodeURI(
                    "Hi Daniel! Habe auf deiner Seite das Angebot für Fotografie gesehen...",
                )}`}
                target="_blank"
                referrerPolicy="no-referrer"
                className="sm:hidden"
                rel="noreferrer"
            >
                <img src={ic_whatsapp} alt="Jetzt auf Whatsapp anschreiben" />
            </a>
            <form
                onSubmit={submit}
                className="w-full sm:mt-4 relative"
                id="inquiryForm"
                onClick={(e) => trackFormClick(e)}
            >
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                        <InputLabel htmlFor="name" value="Name" className="" />

                        <TextInput
                            id="name"
                            name="name"
                            value={data.name}
                            className="mt-1 block w-full"
                            autoComplete="name"
                            onChange={(e) => setData("name", e.target.value)}
                            required
                        />

                        <InputError message={errors.name} className="mt-2" />
                    </div>

                    <div className="sm:col-span-3">
                        <InputLabel
                            htmlFor="origin"
                            value="Woher kommst du?"
                            className=""
                        />

                        <TextInput
                            id="origin"
                            name="origin"
                            value={data.origin}
                            className="mt-1 block w-full"
                            autoComplete="street-address"
                            onChange={(e) => setData("origin", e.target.value)}
                            required
                        />

                        <InputError message={errors.origin} className="mt-2" />
                    </div>

                    <div className={"text-center sm:col-span-6 text-xs"}>
                        <p>Eine der folgenden Kontaktmöglichkeiten reicht</p>
                        <InputError
                            message={errors.email}
                            className="sm:col-span-6 text-center"
                        />
                    </div>

                    <div className="sm:col-span-3">
                        <InputLabel
                            htmlFor="phone"
                            value="Telefonnummer"
                            className=""
                        />

                        <TextInput
                            id="phone"
                            name="phone"
                            value={data.phone}
                            className={`mt-1 block w-full ${
                                errors.email &&
                                "bg-red-100 border-red-300 outline-red-300"
                            }`}
                            autoComplete="phone"
                            onChange={(e) => setData("phone", e.target.value)}
                        />
                    </div>

                    <div className="sm:col-span-3">
                        <InputLabel
                            htmlFor="email"
                            value="E-Mail"
                            className=""
                        />

                        <TextInput
                            id="email"
                            name="email"
                            value={data.email}
                            className={`mt-1 block w-full ${
                                errors.email &&
                                "bg-red-100 border-red-300 outline-red-300"
                            }`}
                            autoComplete="email"
                            onChange={(e) => setData("email", e.target.value)}
                        />
                    </div>

                    {/*<div className="sm:col-span-2">*/}
                    {/*    <InputLabel*/}
                    {/*        htmlFor="instagram"*/}
                    {/*        value="Instagram"*/}
                    {/*        className=""*/}
                    {/*    />*/}

                    {/*    <TextInput*/}
                    {/*        id="instagram"*/}
                    {/*        name="instagram"*/}
                    {/*        value={data.instagram}*/}
                    {/*        className={`mt-1 block w-full ${*/}
                    {/*            errors.email &&*/}
                    {/*            "bg-red-100 border-red-300 outline-red-300"*/}
                    {/*        }`}*/}
                    {/*        autoComplete="instagram"*/}
                    {/*        onChange={(e) =>*/}
                    {/*            setData("instagram", e.target.value)*/}
                    {/*        }*/}
                    {/*    />*/}
                    {/*</div>*/}

                    <div className="sm:col-span-6">
                        <InputLabel
                            htmlFor="notes"
                            value="Hast du noch Fragen? Welche Art von Fotos möchtest du haben?"
                            className=""
                        />

                        <textarea
                            id="notes"
                            name="notes"
                            value={data.notes}
                            placeholder=""
                            className={
                                "text-black border-gray-300 focus:border-blue-500 focus:ring-blue-500 rounded-md shadow-sm mt-1 block w-full"
                            }
                            onChange={(e) => setData("notes", e.target.value)}
                        />

                        <InputError message={errors.notes} className="mt-2" />
                    </div>
                </div>

                <div className="mt-4 flex items-center justify-center">
                    {/*<div className="sticky bottom-0 left-0 right-0 flex self-end">*/}
                    <PrimaryButton
                        className="bg-slate-500 grow text-center items-center justify-center pt-4 pb-4"
                        disabled={processing}
                    >
                        Anfrage senden
                    </PrimaryButton>
                </div>

                <div className="text-slate-300 transition text-sm mt-4 text-center">
                    Die abgesendeten Daten werden nur zum Zweck der Bearbeitung
                    deines Anliegens verarbeitet. Weitere Infos:{" "}
                    <a
                        href="/datenschutz"
                        className="font-semibold text-blue-400"
                    >
                        Datenschutzerklärung
                    </a>
                    .
                </div>
            </form>
        </div>
    );
}
