import Button from "@/Components/Button.jsx";

export default function PrimaryButton({
    className = "",
    disabled,
    children,
    ...props
}) {
    return (
        <Button {...props} primary disabled={disabled}>
            {children}
        </Button>
    );
}
