import { useEffect, useState } from "react";

function useWindowSize() {
    const defaultSize = {
        width: 375,
        height: 800,
        devicePixelRatio: 1,
    };

    // if (typeof window === "undefined") {
    //     return defaultSize;
    // }

    const [windowSize, setWindowSize] = useState(defaultSize);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
                devicePixelRatio: window.devicePixelRatio,
            });
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
}

export default useWindowSize;
