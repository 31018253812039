import { Link } from "@inertiajs/react";

export default function Button({
    className = "",
    disabled,
    children,
    primary = false,
    danger = false,
    href = false,
    ...props
}) {
    let color = "white";
    let hoverColor = "slate";
    let textColor = "slate";
    let textColorGrade = "950";
    let bgColorGrade = "50";
    let bgHoverColorGrade = "100";
    let borderColor = "slate-600";

    if (danger) {
        color = "red";
        textColor = "red";
        borderColor = "red-500";
        textColorGrade = "500";
        hoverColor = "red";
    } else {
        if (primary) {
            color = "blue";
        }
    }

    if (primary) {
        bgColorGrade = "700";
        bgHoverColorGrade = "800";
        textColorGrade = "50";
        textColor = color;
        borderColor = "transparent";
        hoverColor = color;
    }

    const bgClass =
        color === "white" ? "bg-white" : ` bg-${color}-${bgColorGrade}`;
    const textClass = `text-${textColor}-${textColorGrade} font-semibold text-xs uppercase tracking-widest`;
    const borderClass = `border border-${borderColor}`;
    const hoverClass = `hover:bg-${hoverColor}-${bgHoverColorGrade}`;
    const focusClass = `focus:bg-${color}-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`;
    const activeClass = `active:bg-${color}-900`;

    const classNameFull =
        `inline-flex items-center px-4 py-2 ${bgClass} ${textClass} ${borderClass} ${hoverClass} ${focusClass} ${activeClass} rounded-md transition ease-in-out duration-150 ${
            disabled && "opacity-25"
        } ` + className;

    if (href) {
        return (
            <Link
                {...props}
                className={classNameFull}
                disabled={disabled}
                href={href}
            >
                {children}
            </Link>
        );
    }

    return (
        <button {...props} className={classNameFull} disabled={disabled}>
            {children}
        </button>
    );
}
